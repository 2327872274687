<template>
  <div class="box">
    <div class="content">
<!--      <div class="banner"></div>-->
      <div class="main">
        <div class="lunbo">
          <el-carousel :interval="3000" arrow="always" height="335px" indicator-position="none">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
              <div class="title">{{item.journalisTitle}}</div>
              <el-image class="img" :src="echoImg(item.bannerUrl)" fit="fit" @click="goDetails('',item.id)"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <template v-for="(item,index) in subjectList" @key="index">
          <ul class="ul" :class="'ul_'+index" v-if="index<=5" :key="index">
            <li :class="'li_'+index">
              <div class="titleBox">
<!--                <img class="icon" src="../images/handball-people1.png"/>-->
                <span class="title1" :style="'color:'+color">{{item.navigationName}}</span>
                <span class="more" :style="'background:'+color" @click="more(item.navigationName,item.id,item.navigationType)">更多 +</span>
              </div>
              <ul :class="index==0?'ulBg':''">
                <template v-for="(dates,idxs) in item.journalism" @key="idxs">
                  <li v-if="index==4?idxs<6:index==5?idxs<6:idxs<8" @click="goDetails(item.navigationName, dates.id)" :key="idxs" class="theme">
                    <div class="imgDiv" v-if="index==5">
                      <div class="imgBox">
                        <img :src="echoImg(dates.coverImg)"/>
                        <span>{{toDate(dates.createTime,"yyyy-MM-dd")}}</span>
                      </div>
                      <div class="mapTitle">{{dates.journalisTitle}}</div>
                    </div>
                    <div class="newTitle" v-else>
                      <img class="coverImg" v-if="index==4" :src="echoImg(dates.coverImg)"/>
                      <span class="span3" v-if="index==0" :style="{background:color}">{{idxs+1}}</span>
                      <span class="span1">{{dates.journalisTitle}}</span>
                      <span class="span2">{{toDate(dates.createTime,"yyyy-MM-dd")}}</span>
                    </div>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </template>
        <ul class="partners">
          <div class="titleBox titleBox1">
<!--            <img class="icon" src="../images/handball-people1.png"/>-->
            <span class="title1" :style="'color:'+color">友情链接</span>
          </div>
          <div class="containt">
            <ul :style="{ left: calleft + 'px', width: widthData + 'px' }" v-on:mouseover="stopMove()" v-on:mouseout="move()" class="imgBoxoul">
              <li v-for="(item, index) in linksList2" :key="index" ref="lis">
                <div class="lbTitle" @click="openLinks(item.linkUrl)">{{item.linkName}}</div>
<!--                <el-image class="img" :src="echoImg(item.imgUrl)" @click="openLinks(item.linkUrl)" fit="fit"></el-image>-->
              </li>
              <li v-for="(dates, idx) in linksList2" :key="'c_' + idx" ref="lis1">
                <div class="lbTitle" @click="openLinks(dates.linkUrl)" >{{dates.linkName}}</div>
<!--                <el-image class="img" :src="echoImg(dates.imgUrl)" @click="openLinks(dates.linkUrl)" fit="fit"></el-image>-->
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
    <div class="fixedBox" :style="{'top':setTop+'px','left':setLeft+'px'}" v-if="isShowBtn">
      <ul class="ul1">
        <li @click="myRegistration">我的注册</li>
        <li v-for="(item,index) in fixedBoxList" :key="index" @click="registrationBtn(item.infoCategory)">{{item.name}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { selectLink,selectMapDepot,selectBanner,selectList,getTextarea } from "@/api/template";
// import AboutUs from "./aboutUs";
import { mapState } from "vuex";
import {baseInfoPersonnelFormPage, getNavigationQueryId} from "../../../api/template";
export default {
  name: "Index",
  // components: { AboutUs },
  data() {
    return {
      setTop:400,
      setLeft:0,
      isShowBtn:false,
      linksList: [],
      mapDepotList: [],
      linksList1:[],
      linksList2:[],
      bannerList: [],
      subjectList: [],
      synopsis:'',
      introduce:'',
      calleft: 0,
      speed: 1,
      timer: '',
      lyList:['../images/gg1.jpg','../images/gg2.jpg'],
      currentScroll:0,
      fixedBoxList:[]
    };
  },
  created(){
    this.init()
    this.move()
  },
  computed: {
    widthData(){
      return 190 * Number(this.linksList2.length * 2)
    },
    ...mapState(["color"]),
  },
  methods: {
    init(){
      this.getSelectBanner()
      this.getSelectList()
      this.getSelectMapDepot()
      this.getSelectLink()
      // console.log(window.localStorage.getItem("token"))
      if(window.localStorage.getItem("token")){
        this.getBaseInfoPersonnelFormPage()
      }
      if(window.innerWidth>1330){
        this.setLeft = 1220 + (window.innerWidth-1200)/2
      }else {
        this.setLeft = window.innerWidth-130
      }
    },
    getSelectLink(){
      let self = this
      selectLink().then(res =>{
        if(res.code == 200){
          res.data.forEach(item =>{
            if(!item.imgUrl){
              this.linksList1.push(item)
            }else if(item.imgUrl){
              this.linksList2.push(item)
            }
          })
          this.linksList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectBanner(){
      let self = this
      selectBanner().then(res =>{
        if(res.code == 200){
          this.bannerList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          this.mapDepotList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectList(){
      let self = this
      selectList().then(res =>{
        if(res.code == 200){
          res.data.forEach(item=>{
            if(item.navigationType == 5){
              // getNavigationQueryId(item.id).then(dates=>{
              //   if(dates.code == 200){
              //     this.fixedBoxList = dates.data.witem
              //     this.setTop = (window.innerHeight)/2 - this.fixedBoxList.length*42 - 116
              //     // console.log(this.setTop)
              //   }
              // })
            }else {
              this.subjectList.push(item)
            }
          })
          // this.subjectList = res.data
          // this.subjectList = res.data.reverse()
          // console.log(this.subjectList)
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    openLinks(linkUrl){
      window.open(linkUrl,'_blank')
    },
    more(navigationName,id,type){
      this.$router.push({params: {name:navigationName,id:id,type: type},name:'tenth-list'})
    },
    goDetails(navigationName,id) {
      let data = {
        name: navigationName,
        id: id,
      }
      window.sessionStorage.setItem('detailsValue', JSON.stringify(data))
      this.$router.push('/tenth-details')
    },
    getTextareaFun(journalisContent,type){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        if(type == 'synopsis'){
          this.synopsis = res.data.dataHtml
        }
        if(type == 'introduce'){
          this.introduce = res.data.dataHtml
        }
      })
    },
    move() {
      this.timer = setInterval(this.starMove, 20);
    },
    //开始移动
    starMove() {
      this.calleft -= 1.2;//*this.speed
      if (this.calleft <= -this.linksList2.length*191) {
        this.calleft = 0;
      }
    },
    //鼠标悬停时停止移动
    stopMove() {
      clearInterval(this.timer);
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
    //获取人员类型模本
    getBaseInfoPersonnelFormPage(){
      let params = {
        currentPage:1,
        pageSize:20,
        organizeId:window.localStorage.getItem("domainTid"),
        infoCategory:''
      }
      baseInfoPersonnelFormPage(params).then(res=>{
        if(res.code == 200){
          this.isShowBtn = true
          this.fixedBoxList = res.data.list
          this.setTop = (window.innerHeight)/2 - this.fixedBoxList.length*52/2
        }
      })
    },
    registrationBtn(infoCategory){
      this.$router.push({params: {infoCategory:infoCategory},name:'tenth-registration'})
    },
    myRegistration(){
      this.$router.push({name:'tenth-myRegistration'})
    }
  },
};
</script>
<style lang="scss" scoped>
  .content{
    position: relative;
    z-index: 1;
    .banner{
      width: 100%;
      height: 450px;
      background: url("../images/bannerBg.jpg") no-repeat center center;
      background-size: 100% 100%;
      .img{
        width: 100%;
        height: 100%;
      }
    }
    .main{
      position: relative;
      width: 1160px;
      background: #fff;
      margin: 20px auto;
      padding: 0 20px 20px;
      .titleBox{
        position: relative;
        width: 100%;
        background: #f2f2f2;
        color: #666;
        font-size: 16px;
        height: 36px;
        line-height: 36px;
        .icon{
          vertical-align: middle;
          border-style: none;
          margin-right: 5px;
          margin-left: 10px;
        }
        .title1{
          font-weight: bold;
          color: #e60012;
          margin-left: 10px;
        }
        .more{
          display: inline-block;
          width: 64px;
          text-align: center;
          position: absolute;
          right: 0;
          top: 0;
          height: 36px;
          line-height: 36px;
          background: #e60012;
          color: #fff;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .titleBox1{
        text-align: center;
      }
      .lunbo{
        position: relative;
        width: 630px;
        height: 335px;
        display: inline-block;
        cursor: pointer;
        margin-top: 20px;
        .title{
          position: absolute;
          left: 0;
          bottom: 0;
          //width: 100%;
          width: 590px;
          background: rgba(0,0,0,0.6);
          z-index: 99;
          color: #fff;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .img{
          width: 100%;
        }
        /deep/ .el-carousel__arrow{
          background-color:rgba(31,45,61,.4);
        }
        /deep/.el-carousel__indicators{
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      .ul{
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;
        .li_0{
          display: inline-block;
          width: 500px;
          margin-left: 30px;
          cursor: pointer;
          .ulBg{
            width: 480px;
            //background: #f7f7f7;
            padding: 10px;
          }
          .newTitle{
            height: 30px;
            line-height: 30px;
            margin-top: 5px;
            .span3{
              float: left;
              width: 5%;
              background: #ed5d52;
              color: #fff;
              text-align: center;
              margin-right: 2%;
            }
            .span1{
              float: left;
              width: 70%;
              //font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .span2{
              float: right;
              width: 22%;
              text-align: right;
            }
          }
        }
        .li_1,.li_2,.li_3{
          display: inline-block;
          width: 370px;
          vertical-align: top;
          cursor: pointer;
          margin-right: 25px;
          .newTitle{
            width: 350px;
            height: 20px;
            line-height: 20px;
            margin-top: 10px;
            padding: 0 10px;
            .span1{
              float: left;
              width: 75%;
              height: 20px;
              line-height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
            }
            .span2{
              float: right;
              width: 25%;
              text-align: right;
            }
          }
        }
        .li_3{
          margin-right: 0;
        }
      }
      .ul_4{
        width: 765px;
        margin-top: 20px;
        ul{
          li{
            display: inline-block;
            position: relative;
            width: 370px;
            padding: 18px 0;
            border-bottom: 1px solid #eee;
            font-weight: bold;
            cursor: pointer;
            height: 90px;
            .coverImg{
              position: absolute;
              left: 10px;
              top: 20px;
              width: 142px;
              height: 90px;
              margin-right: 10px;
            }
            .span1{
              position: absolute;
              left: 162px;
              top: 35px;
              width: 198px;
              height: 20px;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .span2{
              position: absolute;
              left: 162px;
              bottom: 35px;
              width: 198px;
              font-size: 16px;
              color: #999;
              //float: right;
            }
          }
          li:nth-child(odd){//奇数行
            margin-right: 25px;
          }
          li:nth-child(even){//偶数行
            margin-right: 0;
          }
        }
      }
      .ul_5{
        display: inline-block;
        width: 370px;
        margin-left: 25px;
        margin-top: 20px;
        .titleBox{
          margin-bottom: 18px;
        }
        li{
          display: inline-block;
          width: 100%;
          cursor: pointer;
          ul{
            li{
              float: left;
              width: 175px;
              .imgBox{
                position: relative;
                width: 175px;
                height: 106px;
                img{
                  width: 100%;
                  height: 100%;
                }
                span{
                  position: absolute;
                  bottom: 0;
                  background: rgba(0,0,0,.6);
                  color: #fff;
                  width: 100%;
                  height: 20px;
                  font-size: 12px;
                  line-height: 20px;
                  text-align: center;
                  left: 0;
                }
              }
              .mapTitle{
                width: 145px;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 20px;
              }
            }
            li:nth-child(odd){//奇数行
              margin-right: 20px;
            }
            li:nth-child(even){//偶数行
              margin-right: 0;
            }
          }
        }

      }
      .partners{
        width: 100%;
        margin-top: 20px;
        .bTitle{
          height: 36px;
          line-height: 36px;
          background: #f2f2f2;
          font-size: 16px;
          font-weight: bold;
          color: #e60012;
          padding-left: 10px;
        }
        .containt {
          position: relative;
          //padding: 20px 0;
          overflow-y: auto;
          width: 100%;
          height: 50px;
          //height: 140px;
          overflow: hidden;
          margin: 0 auto;
          ul {
            width: 100%;
            //height: 120px;
            position: absolute;
            left: 0px;
            cursor: pointer;
            z-index: 10;
            overflow: hidden;
            margin: 20px auto;
            li {
              float: left;
              width: 150px;
              //height: 120px;
              //padding: 30px 48px 0;
              overflow: hidden;
              text-align: center;
              border-right: 1px solid #eee;
              padding: 0 20px;
              .img{
                height: 100%;
                cursor: pointer;
              }
              .lbTitle{
                font-size: 20px;
                color: #333;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
  .theme:hover{
    //border-bottom: 1px solid rgb(20, 146, 230);
    color: rgb(20, 146, 230);
  }
  .fixedBox{
    position: fixed;
    left: 0px;
    top: 0;
    padding: 5px 0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    z-index: 999;
    .name{
      line-height: 32px;
    }
    .ul1{
      position: relative;
      li{
        width: 130px;
        line-height: 32px;
        text-align: center;
        background: rgba(20, 146, 230,.5);
        margin-top: 10px;
        border-radius: 5px;
      }
      .icon{
        position: absolute;
        font-size: 40px;
        top: -45px;
        left: 50%;
        margin-left: -20px;
        color: red;
      }
    }
  }
</style>
